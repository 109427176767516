
export class UIElements {
  public static dropDowns: { [key: string]: { value: string | number, label?: string }[] } = {


    "simplecalc": [
      { value: 'Yes' },
      { value: 'No' },
      { value: 'MLFB' }
    ],
    "selection": [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
    ],



    "ContinuousMonitoring": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "IncidentManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "NetworkManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "DomainManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "SchedulefrequencyPP": [
      { value: 1 },
      { value: 2 },
      { value: 4 },
      { value: 12 }
    ],
    "Preventive&PeriodicMaintenance": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "SchedulefrequencyUP": [
      { value: 1 },
      { value: 2 },
      { value: 4 },
      { value: 12 }
    ],
    "Updates&pacthes": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "Back-up&Restore": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "VulnerabilityManagement": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "AnomalyDetection": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "SOCaaS": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "RemoteSupport": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "ServiceDesk": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "ServiceRequest": [
      { value: 'Yes' },
      { value: 'No' }
    ],

    "cRSP": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "onboardingcustomer": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "onboardinganomaly": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "onboardinganomalyvisible": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "onboardingSOCaaS": [
      { value: 'Yes' },
      { value: 'No' }
    ],
    "onboardingSOCaaSvisible": [
      { value: 'Yes' },
      { value: 'No' }
    ],



    "Contractduration": [
      { value: 1 },
      { value: 3 },
      { value: 5 },
      { value: 7 }
    ],
     "typeofpricing": [
      { value: "0", label: "GA" },
      { value: "1", label: "L" },
      { value: "2", label: "K" },
    ], 
    "activatediscount": [
      { value: 'Yes' },
      { value: 'No' }
    ],


    "Country": [
      { value: 'AD,"Andorra"' },
      { value: 'AE,"United Arab Emirates"' },
      { value: 'AF,"Afghanistan"' },
      { value: 'AG,"Antigua and Barbuda"' },
      { value: 'AI,"Anguilla"' },
      { value: 'AL,"Albania"' },
      { value: 'AM,"Armenia"' },
      { value: 'AO,"Angola"' },
      { value: 'AQ,"Antarctica"' },
      { value: 'AR,"Argentina"' },
      { value: 'AS,"American Samoa"' },
      { value: 'AT,"Austria"' },
      { value: 'AU,"Australia"' },
      { value: 'AW,"Aruba"' },
      { value: 'AX,"Åland Islands"' },
      { value: 'AZ,"Azerbaijan"' },
      { value: 'BA,"Bosnia and Herzegovina"' },
      { value: 'BB,"Barbados"' },
      { value: 'BD,"Bangladesh"' },
      { value: 'BE,"Belgium"' },
      { value: 'BF,"Burkina Faso"' },
      { value: 'BG,"Bulgaria"' },
      { value: 'BH,"Bahrain"' },
      { value: 'BI,"Burundi"' },
      { value: 'BJ,"Benin"' },
      { value: 'BL,"Saint Barthélemy"' },
      { value: 'BM,"Bermuda"' },
      { value: 'BN,"Brunei Darussalam"' },
      { value: 'BO,"Bolivia (Plurinational State of)"' },
      { value: 'BQ,"Bonaire, Sint Eustatius and Saba"' },
      { value: 'BR,"Brazil"' },
      { value: 'BS,"Bahamas"' },
      { value: 'BT,"Bhutan"' },
      { value: 'BV,"Bouvet Island"' },
      { value: 'BW,"Botswana"' },
      { value: 'BY,"Belarus"' },
      { value: 'BZ,"Belize"' },
      { value: 'CA,"Canada"' },
      { value: 'CC,"Cocos (Keeling) Islands"' },
      { value: 'CD,"Congo (Democratic Republic of the)"' },
      { value: 'CF,"Central African Republic"' },
      { value: 'CG,"Congo"' },
      { value: 'CH,"Switzerland"' },
      { value: 'CI,"Côte d\'Ivoire"' },
      { value: 'CK,"Cook Islands"' },
      { value: 'CL,"Chile"' },
      { value: 'CM,"Cameroon"' },
      { value: 'CN,"China"' },
      { value: 'CO,"Colombia"' },
      { value: 'CR,"Costa Rica"' },
      { value: 'CU,"Cuba"' },
      { value: 'CV,"Cabo Verde"' },
      { value: 'CW,"Curaçao"' },
      { value: 'CX,"Christmas Island"' },
      { value: 'CY,"Cyprus"' },
      { value: 'CZ,"Czechia"' },
      { value: 'DE,"Germany"' },
      { value: 'DJ,"Djibouti"' },
      { value: 'DK,"Denmark"' },
      { value: 'DM,"Dominica"' },
      { value: 'DO,"Dominican Republic"' },
      { value: 'DZ,"Algeria"' },
      { value: 'EC,"Ecuador"' },
      { value: 'EE,"Estonia"' },
      { value: 'EG,"Egypt"' },
      { value: 'EH,"Western Sahara"' },
      { value: 'ER,"Eritrea"' },
      { value: 'ES,"Spain"' },
      { value: 'ET,"Ethiopia"' },
      { value: 'FI,"Finland"' },
      { value: 'FJ,"Fiji"' },
      { value: 'FK,"Falkland Islands (Malvinas)"' },
      { value: 'FM,"Micronesia (Federated States of)"' },
      { value: 'FO,"Faroe Islands"' },
      { value: 'FR,"France"' },
      { value: 'GA,"Gabon"' },
      { value: 'GB,"United Kingdom"' },
      { value: 'GD,"Grenada"' },
      { value: 'GE,"Georgia"' },
      { value: 'GF,"French Guiana"' },
      { value: 'GG,"Guernsey"' },
      { value: 'GH,"Ghana"' },
      { value: 'GI,"Gibraltar"' },
      { value: 'GL,"Greenland"' },
      { value: 'GM,"Gambia"' },
      { value: 'GN,"Guinea"' },
      { value: 'GP,"Guadeloupe"' },
      { value: 'GQ,"Equatorial Guinea"' },
      { value: 'GR,"Greece"' },
      { value: 'GS,"South Georgia and the South Sandwich Islands"' },
      { value: 'GT,"Guatemala"' },
      { value: 'GU,"Guam"' },
      { value: 'GW,"Guinea-Bissau"' },
      { value: 'GY,"Guyana"' },
      { value: 'HK,"Hong Kong"' },
      { value: 'HM,"Heard Island and McDonald Islands"' },
      { value: 'HN,"Honduras"' },
      { value: 'HR,"Croatia"' },
      { value: 'HT,"Haiti"' },
      { value: 'HU,"Hungary"' },
      { value: 'ID,"Indonesia"' },
      { value: 'IE,"Ireland"' },
      { value: 'IL,"Israel"' },
      { value: 'IM,"Isle of Man"' },
      { value: 'IN,"India"' },
      { value: 'IO,"British Indian Ocean Territory"' },
      { value: 'IQ,"Iraq"' },
      { value: 'IR,"Iran (Islamic Republic of)"' },
      { value: 'IS,"Iceland"' },
      { value: 'IT,"Italy"' },
      { value: 'JE,"Jersey"' },
      { value: 'JM,"Jamaica"' },
      { value: 'JO,"Jordan"' },
      { value: 'JP,"Japan"' },
      { value: 'KE,"Kenya"' },
      { value: 'KG,"Kyrgyzstan"' },
      { value: 'KH,"Cambodia"' },
      { value: 'KI,"Kiribati"' },
      { value: 'KM,"Comoros"' },
      { value: 'KN,"Saint Kitts and Nevis"' },
      { value: 'KP,"Korea (Democratic People\'s Republic of)"' },
      { value: 'KR,"Korea, Republic of"' },
      { value: 'KW,"Kuwait"' },
      { value: 'KY,"Cayman Islands"' },
      { value: 'KZ,"Kazakhstan"' },
      { value: 'LA,"Lao People\'s Democratic Republic"' },
      { value: 'LB,"Lebanon"' },
      { value: 'LC,"Saint Lucia"' },
      { value: 'LI,"Liechtenstein"' },
      { value: 'LK,"Sri Lanka"' },
      { value: 'LR,"Liberia"' },
      { value: 'LS,"Lesotho"' },
      { value: 'LT,"Lithuania"' },
      { value: 'LU,"Luxembourg"' },
      { value: 'LV,"Latvia"' },
      { value: 'LY,"Libya"' },
      { value: 'MA,"Morocco"' },
      { value: 'MC,"Monaco"' },
      { value: 'MD,"Moldova, Republic of"' },
      { value: 'ME,"Montenegro"' },
      { value: 'MF,"Saint Martin (French part)"' },
      { value: 'MG,"Madagascar"' },
      { value: 'MH,"Marshall Islands"' },
      { value: 'MK,"North Macedonia"' },
      { value: 'ML,"Mali"' },
      { value: 'MM,"Myanmar"' },
      { value: 'MN,"Mongolia"' },
      { value: 'MO,"Macao"' },
      { value: 'MP,"Northern Mariana Islands"' },
      { value: 'MQ,"Martinique"' },
      { value: 'MR,"Mauritania"' },
      { value: 'MS,"Montserrat"' },
      { value: 'MT,"Malta"' },
      { value: 'MU,"Mauritius"' },
      { value: 'MV,"Maldives"' },
      { value: 'MW,"Malawi"' },
      { value: 'MX,"Mexico"' },
      { value: 'MY,"Malaysia"' },
      { value: 'MZ,"Mozambique"' },
      { value: 'NA,"Namibia"' },
      { value: 'NC,"New Caledonia"' },
      { value: 'NE,"Niger"' },
      { value: 'NF,"Norfolk Island"' },
      { value: 'NG,"Nigeria"' },
      { value: 'NI,"Nicaragua"' },
      { value: 'NL,"Netherlands"' },
      { value: 'NO,"Norway"' },
      { value: 'NP,"Nepal"' },
      { value: 'NR,"Nauru"' },
      { value: 'NU,"Niue"' },
      { value: 'NZ,"New Zealand"' },
      { value: 'OM,"Oman"' },
      { value: 'PA,"Panama"' },
      { value: 'PE,"Peru"' },
      { value: 'PF,"French Polynesia"' },
      { value: 'PG,"Papua New Guinea"' },
      { value: 'PH,"Philippines"' },
      { value: 'PK,"Pakistan"' },
      { value: 'PL,"Poland"' },
      { value: 'PM,"Saint Pierre and Miquelon"' },
      { value: 'PN,"Pitcairn"' },
      { value: 'PR,"Puerto Rico"' },
      { value: 'PS,"Palestine, State of"' },
      { value: 'PT,"Portugal"' },
      { value: 'PW,"Palau"' },
      { value: 'PY,"Paraguay"' },
      { value: 'QA,"Qatar"' },
      { value: 'RE,"Réunion"' },
      { value: 'RO,"Romania"' },
      { value: 'RS,"Serbia"' },
      { value: 'RU,"Russian Federation"' },
      { value: 'RW,"Rwanda"' },
      { value: 'SA,"Saudi Arabia"' },
      { value: 'SB,"Solomon Islands"' },
      { value: 'SC,"Seychelles"' },
      { value: 'SD,"Sudan"' },
      { value: 'SE,"Sweden"' },
      { value: 'SG,"Singapore"' },
      { value: 'SH,"Saint Helena, Ascension and Tristan da Cunha"' },
      { value: 'SI,"Slovenia"' },
      { value: 'SJ,"Svalbard and Jan Mayen"' },
      { value: 'SK,"Slovakia"' },
      { value: 'SL,"Sierra Leone"' },
      { value: 'SM,"San Marino"' },
      { value: 'SN,"Senegal"' },
      { value: 'SO,"Somalia"' },
      { value: 'SR,"Suriname"' },
      { value: 'SS,"South Sudan"' },
      { value: 'ST,"Sao Tome and Principe"' },
      { value: 'SV,"El Salvador"' },
      { value: 'SX,"Sint Maarten (Dutch part)"' },
      { value: 'SY,"Syrian Arab Republic"' },
      { value: 'SZ,"Eswatini"' },
      { value: 'TC,"Turks and Caicos Islands"' },
      { value: 'TD,"Chad"' },
      { value: 'TF,"French Southern Territories"' },
      { value: 'TG,"Togo"' },
      { value: 'TH,"Thailand"' },
      { value: 'TJ,"Tajikistan"' },
      { value: 'TK,"Tokelau"' },
      { value: 'TL,"Timor-Leste"' },
      { value: 'TM,"Turkmenistan"' },
      { value: 'TN,"Tunisia"' },
      { value: 'TO,"Tonga"' },
      { value: 'TR,"Turkey"' },
      { value: 'TT,"Trinidad and Tobago"' },
      { value: 'TV,"Tuvalu"' },
      { value: 'TW,"Taiwan, Province of China"' },
      { value: 'TZ,"Tanzania, United Republic of"' },
      { value: 'UA,"Ukraine"' },
      { value: 'UG,"Uganda"' },
      { value: 'UM,"United States Minor Outlying Islands"' },
      { value: 'US,"United States of America"' },
      { value: 'UY,"Uruguay"' },
      { value: 'UZ,"Uzbekistan"' },
      { value: 'VA,"Holy See"' },
      { value: 'VC,"Saint Vincent and the Grenadines"' },
      { value: 'VE,"Venezuela (Bolivarian Republic of)"' },
      { value: 'VG,"Virgin Islands (British)"' },
      { value: 'VI,"Virgin Islands (U.S.)"' },
      { value: 'VN,"Viet Nam"' },
      { value: 'VU,"Vanuatu"' },
      { value: 'WF,"Wallis and Futuna"' },
      { value: 'WS,"Samoa"' },
      { value: 'YE,"Yemen"' },
      { value: 'YT,"Mayotte"' },
      { value: 'ZA,"South Africa"' },
      { value: 'ZM,"Zambia"' },
      { value: 'ZW,"Zimbabwe"' },
    ]
  }
}
