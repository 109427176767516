import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDataService } from '../services/form-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTransferService } from '../services/data-transfer.service';
import { Project, ProjectService } from '../services/project.service';
import { mergeMap } from 'rxjs';
import { DataService } from '../services/data.service';
import { ToastService } from '@siemens/ix-angular';

@Component({
  selector: 'app-project-calculation',
  templateUrl: './project-calculation.component.html',
  styleUrls: ['./project-calculation.component.css']
})
export class ProjectCalculationComponent implements OnInit {

  State = State;
  protected stateMaschine = new WorkflowStateMachine();
  isSimpleCalculation: boolean = true;

  currentProject: Project | undefined;

  basisInfoForm: FormGroup = this.formBuilder.group({
    Customer: [''],
    Country: [''],
    Location: [''],
    ContactRC: [''],
    "remarks field": [''],
    "simplecalc": [''],
    "servicetext1": [''],
    "servicetext2": [''],
    "servicetext3": [''],
    "servicetext4": [''],
    "servicetext5": [''],
    "servicetext6": [''],
    "servicetext7": [''],
    "servicetext8": [''],
    "selection": [''],
    "hostcount": [''],
    "MLFBshort1": [''],
    "MLFBshort2": [''],
    "MLFBshort3": [''],
    "MLFBshort4": [''],
    "MLFBshort5": [''],
    "MLFBshort6": [''],
    "MLFBshort7": [''],
    "MLFB1": [''],
    "MLFB2": [''],
    "MLFB3": [''],
    "MLFB4": [''],
    "MLFB5": [''],
    "MLFB6": [''],
    "MLFB7": [''],
    "MLFBselected": [''],

    Customertooltiptext: [''],
    Countrytooltiptext: [''],
    Locationtooltiptext: [''],
    ContactRCtooltiptext: [''],
    "remarks fieldtooltiptext": [''],
    simplecalctooltiptext: [''],
    selectiontooltiptext: [''],
    hostcounttooltiptext: [''],


  });

  equipmentInventoryForm: FormGroup = this.formBuilder.group({
    Engineeringstation: [''],
    "OSServersincluding_BatchandRoutecontrol": [''],
    OSClients: [''],
    Dataclients: [''],
    "OtherProductionsystems": [''],

    "DataExchangeServer": [''],
    "Jumphost": [''],
    Domain: [''],
    NetworkMonitorServer: [''],
    Management: [''],
    "DNS": [''],
    EndpointProtection: [''],
    "InformationServer": [''],
    "UpdateServer_WSUS": [''],
    "OtherWindows": [''],
    "OtherOS": [''],

    Hosts: [''],
    SAN_vSANsolution: [''],
    iDMZhost: [''],
    coreswitches: [''],
    firewalls: [''],
    "SIDSIB&R": [''],
    PH: [''],
    UPS: [''],
    NAS: [''],
    USBconnectorHUB: [''],
    Thinclients: [''],

    SwitchesPCN: [''],
    SwitchesCSN: [''],
    SwitchesFSN: [''],
    "PLC-AS": [''],
    "PLC-ASvisible": [''],

    Engineeringstationtooltiptext: [''],
    OSServersincluding_BatchandRoutecontroltooltiptext: [''],
    OSClientstooltiptext: [''],
    Dataclientstooltiptext: [''],
    OtherProductionsystemstooltiptext: [''],

    DataExchangeServertooltiptext: [''],
    Jumphosttooltiptext: [''],
    Domaintooltiptext: [''],
    NetworkMonitorServertooltiptext: [''],
    Managementtooltiptext: [''],
    DNStooltiptext: [''],
    EndpointProtectiontooltiptext: [''],
    InformationServertooltiptext: [''],
    UpdateServer_WSUStooltiptext: [''],
    OtherWindowstooltiptext: [''],
    OtherOStooltiptext: [''],

    Hoststooltiptext: [''],
    SAN_vSANsolutiontooltiptext: [''],
    iDMZhosttooltiptext: [''],
    coreswitchestooltiptext: [''],
    firewallstooltiptext: [''],
    "SIDSIB&Rtooltiptext": [''],
    PHtooltiptext: [''],
    UPStooltiptext: [''],
    NAStooltiptext: [''],
    USBconnectorHUBtooltiptext: [''],
    Thinclientstooltiptext: [''],
    tooltiptext: [''],
    SwitchesPCNtooltiptext: [''],
    SwitchesCSNtooltiptext: [''],
    SwitchesFSNtooltiptext: [''],
    "PLC-AStooltiptext": [''],
    "PLC-ASvisibletooltiptext": [''],
    
  });

  servicesForm: FormGroup = this.formBuilder.group({
    ContinuousMonitoring: [''],
    IncidentManagement: [''],
    NetworkManagement: [''],
    DomainManagement: [''],

    SchedulefrequencyPP: [''],
    "Preventive&PeriodicMaintenance": [''],  
    "SchedulefrequencyUP": [''],
    "Updates&pacthes": [''],
    "Back-up&Restore": [''],

    VulnerabilityManagement: [''],
    AnomalyDetection: [''],
    SOCaaS: [''],

    RemoteSupport: [''],
    ServiceDesk: [''],
    ServiceRequest: [''],
    ServiceRequesthours: [''],

    cRSP: [''],
    onboardingcustomer: [''],
    onboardinganomaly: [''],
    onboardinganomalyvisible: [''],
    onboardingSOCaaS: [''],
    onboardingSOCaaSvisible: [''],

    ContinuousMonitoringtooltiptext: [''],
    IncidentManagementtooltiptext: [''],
    NetworkManagementtooltiptext: [''],
    DomainManagementtooltiptext: [''],

    SchedulefrequencyPPtooltiptext: [''],
    "Preventive&PeriodicMaintenancetooltiptext": [''],
    SchedulefrequencyUPtooltiptext: [''],
    "Updates&pacthestooltiptext": [''],
    "Back-up&Restoretooltiptext": [''],

    VulnerabilityManagementtooltiptext: [''],
    AnomalyDetectiontooltiptext: [''],
    SOCaaStooltiptext: [''],

    RemoteSupporttooltiptext: [''],
    ServiceDesktooltiptext: [''],
    ServiceRequesttooltiptext: [''],
    ServiceRequesthourstooltiptext: [''],

    cRSPtooltiptext: [''],
    onboardingcustomertooltiptext: [''],
    onboardinganomalytooltiptext: [''],
    onboardinganomalyvisibletooltiptext: [''],
    onboardingSOCaaStooltiptext: [''],
    onboardingSOCaaSvisibletooltiptext: [''],


  });

  pricingForm: FormGroup = this.formBuilder.group({
    Contractduration: [''],
    typeofpricing: [''],
    indexation: [''],
    indexationvisible: [''],
    activatediscount: [''],
    indexdisclaimer: [''],
    simplecalc: [''],

    Contractdurationtooltiptext: [''],
    typeofpricingtooltiptext: [''],
    indexationtooltiptext: [''],
    indexationvisibletooltiptext: [''],
    activatediscounttooltiptext: [''],
    indexdisclaimertooltiptext: [''],

  });

  contractForm: FormGroup = this.formBuilder.group({
    typeofpricing1: [''],
    onboarding_total: [''],
    requestbudget_reimbursable: [''],
    RIOpSpackageoprice: [''],
    RIOpspackacketext: [''],
    RIOpSDiscount: [''],
    RIOpSDiscountVisible: [''],
    totalprice: [''],
    genernaldisclaimertext: [''],
    Versiontext: [''],
    descriptiontext: [''],

    typeofpricing1tooltiptext: [''],
    onboarding_totaltooltiptext: [''],
    requestbudget_reimbursabletooltiptext: [''],
    RIOpSpackageopricetooltiptext: [''],
    RIOpspackacketexttooltiptext: [''],
    RIOpSDiscounttooltiptext: [''],
    totalpricetooltiptext: [''],
    genernaldisclaimertexttooltiptext: [''],
    Versiontexttooltiptext: [''],
    descriptiontexttooltiptext: [''],
    RIOpSDiscountVisibletooltiptext: [''],

  });

  constructor(protected router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private formDataService: FormDataService,
    private dataTransferService: DataTransferService, private projectService: ProjectService,
    private dataService: DataService, private readonly toastService: ToastService) { }

  async ngOnInit() {
    this.activatedRoute.params.pipe(
      mergeMap((params) => this.projectService.getProject(+params['id'])))
      .subscribe({ 
        next: async (project) => {          
          sessionStorage.setItem("SelectedProjectId", project.id.toString());
          await this.getIsSimpleCalc();
          this.currentProject = project;
        },
        error: () => {
          this.toastService.show({ 
            title: 'Load data error',
            message: 'We cannot load the data of the project. ', 
            type: "error" 
          });
          this.currentProject = undefined;
          sessionStorage.setItem("SelectedProjectId", "");
          this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
        }}); 
  }

  async getIsSimpleCalc() {
    const formData = await this.dataTransferService.getValuesFromExcel(["simplecalc"]) as any;
    this.isSimpleCalculation = formData["simplecalc"].toString() == "No"? false : true;
  }

  ChangeSimpleCalculation(isSimpleCalculation: boolean) {
    this.isSimpleCalculation = isSimpleCalculation;
  }

  async selectWorkflowStep(state: State) {
    let formCurrentState: FormGroup = this.getFormGroup(this.stateMaschine.currentState);
    const isWritingSuccessful = await this.dataService.tryWriteData(formCurrentState);
    if (isWritingSuccessful) {
      this.stateMaschine.setState(state);
    }    
  }

  getFormGroup(currentState: State): FormGroup {
    switch (currentState) {
      case State.Step1:
        return this.basisInfoForm;
      case State.Step2:
        return this.equipmentInventoryForm;
      case State.Step3:
        return this.servicesForm;
      case State.Step4:
        return this.pricingForm;
      case State.Step5:
        return this.contractForm;
    }
  }

  async NextStep() {
    let formCurrentState: FormGroup = this.getFormGroup(this.stateMaschine.currentState);
    const isWritingSuccessful = await this.dataService.tryWriteData(formCurrentState);
    if (isWritingSuccessful) {
      this.stateMaschine.forward(this.isSimpleCalculation);
    }
  }

  async StepBack() {
    let formCurrentState: FormGroup = this.getFormGroup(this.stateMaschine.currentState);
    const isWritingSuccessful = await this.dataService.tryWriteData(formCurrentState);
    if (isWritingSuccessful) {
      this.stateMaschine.back(this.isSimpleCalculation);
    }
  }

  backButtonClick() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }
}

export enum State {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
}

interface WorkflowStep {
  from: State;
  to: State;
}

const advancedCalcTransitions: WorkflowStep[] = [
  { from: State.Step1, to: State.Step2 },
  { from: State.Step2, to: State.Step3 },
  { from: State.Step3, to: State.Step4 },
  { from: State.Step4, to: State.Step5 },
];

const simpleCalcTransitions: WorkflowStep[] = [
  { from: State.Step1, to: State.Step4 },
  { from: State.Step4, to: State.Step5 },
];

class WorkflowStateMachine {

  currentState: State = State.Step1;

  public forward(isSimpleCalculation: boolean): void {
    const currentStateIndex = this.currentState;
    const transitions = isSimpleCalculation? simpleCalcTransitions : advancedCalcTransitions;
    const transition = transitions.find(
      (t) => t.from === currentStateIndex
    );

    if (transition) {
      this.currentState = transition.to;
    } else {
      throw new Error("Invalid transition");
    }
  }


  public back(isSimpleCalculation: boolean): void {
    const currentStateIndex = this.currentState;
    const transitions = isSimpleCalculation? simpleCalcTransitions : advancedCalcTransitions;
    const transition = transitions.find(
      (t) => t.to === currentStateIndex
    );

    if (transition) {
      this.currentState = transition.from;
    } else {
      throw new Error("Invalid transition");
    }
  }

  public setState(state: State): void {
    this.currentState = state;
  }
}
