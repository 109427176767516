<form [formGroup]="basisInfoForm" class="form">
    <ix-typography format="h3">Basics</ix-typography>
    <ix-layout-grid class="layout-grid-form" gap="12">
        <ix-row>
          <ix-col size="2" size-sm="12">
            <label class="tooltip-customer" aria-describedby="tooltip-customer">Customer</label>
            <ix-tooltip id="tooltip-customer" for=".tooltip-customer">{{basisInfoForm.controls['Customertooltiptext'].value}}</ix-tooltip>   
          </ix-col>
          <ix-col>
            <input type="text" formControlName="Customer">
          </ix-col>
        </ix-row>
        <ix-row>
          <ix-col size="2" size-sm="12">
            <label class="tooltip-Country" aria-describedby="tooltip-Country">Country</label>
        <ix-tooltip id="tooltip-Country" for=".tooltip-Country">{{basisInfoForm.controls['Countrytooltiptext'].value}}</ix-tooltip>
          </ix-col>
          <ix-col>
            <ix-select class="ix-select input-width" name="ix-select-control" formControlName="Country">
                <ix-select-item *ngFor="let option of dropdowns['Country']" label="{{option.value}}" value="{{option.value}}">
                </ix-select-item>
            </ix-select>
          </ix-col>
        </ix-row>
        <ix-row>
            <ix-col size="2" size-sm="12">
              <label class="tooltip-location" aria-describedby="tooltip-location">Location</label>
              <ix-tooltip id="tooltip-location" for=".tooltip-location">{{basisInfoForm.controls['Locationtooltiptext'].value}}</ix-tooltip>   
            </ix-col>
            <ix-col>
                <textarea formControlName="Location"></textarea>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-col size="2" size-sm="12">
              <label class="tooltip-contactrc" aria-describedby="tooltip-contactrc">Contact RC</label>
              <ix-tooltip id="tooltip-contactrc" for=".tooltip-contactrc">{{basisInfoForm.controls['ContactRCtooltiptext'].value}}</ix-tooltip>   
            </ix-col>
            <ix-col>
                <textarea formControlName="ContactRC"></textarea>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-col size="2" size-sm="12">
              <label class="tooltip-remarks" aria-describedby="tooltip-remarks">Remarks</label>
              <ix-tooltip id="tooltip-remarks" for=".tooltip-remarks">{{basisInfoForm.controls['remarks fieldtooltiptext'].value}}</ix-tooltip>   
            </ix-col>
            <ix-col>
                <textarea formControlName="remarks field"></textarea>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-col size="2" size-sm="12">
              <label class="tooltip-simpleCalc" aria-describedby="tooltip-simpleCalc">Simple calculation</label>
              <ix-tooltip id="tooltip-simpleCalc" for=".tooltip-simpleCalc">{{basisInfoForm.controls['simplecalctooltiptext'].value}}</ix-tooltip>   
            </ix-col>
            <ix-col>
                <ix-select class="ix-select input-width" name="ix-select-control" formControlName="simplecalc"
                (valueChange)="ChangeSimpleSelection()">
                <ix-select-item *ngFor="let option of dropdowns['simplecalc']" label="{{option.value}}"
                    value="{{option.value}}">
                </ix-select-item>
            </ix-select>
            </ix-col>
          </ix-row>

          <ix-row *ngIf="basisInfoForm.controls['simplecalc'].value == 'MLFB'">
            <ix-col size="2" size-sm="12">
              <label class="tooltip-mlfb" aria-describedby="tooltip-mlfb">MLFB - Services</label>
              <!-- <ix-tooltip id="tooltip-mlfb" for=".tooltip-mlfb">{{basisInfoForm.controls['mlfbtooltiptext'].value}}</ix-tooltip>    -->
            </ix-col>
            <ix-col>
                <ix-select class="ix-select input-width" name="ix-select-control" formControlName="MLFBselected">
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort1'].value}}"
                        value="{{basisInfoForm.controls['MLFB1'].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort2'].value}}"
                        value="{{basisInfoForm.controls['MLFB2'].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort3'].value}}"
                        value="{{basisInfoForm.controls['MLFB3'].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort4'].value}}"
                        value="{{basisInfoForm.controls['MLFB4'].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort5'].value}}"
                        value="{{basisInfoForm.controls['MLFB5'].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort6'].value}}"
                        value="{{basisInfoForm.controls['MLFB6'].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['MLFBshort7'].value}}"
                        value="{{basisInfoForm.controls['MLFB7'].value}}"></ix-select-item>
                </ix-select>
            </ix-col>
          </ix-row>

          <ix-row *ngIf="basisInfoForm.controls['simplecalc'].value == 'Yes'">
            <ix-col size="2" size-sm="12">
              <label class="tooltip-services" aria-describedby="tooltip-services">Services</label>
              <ix-tooltip id="tooltip-services" for=".tooltip-services">{{basisInfoForm.controls['selectiontooltiptext'].value}}</ix-tooltip>   
            </ix-col>
            <ix-col>
                <ix-select class="ix-select" name="ix-select-control" formControlName="selection">
                    <ix-select-item label="{{basisInfoForm.controls['servicetext1'].value}}"
                        value="{{dropdowns['selection'][0].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext2'].value}}"
                        value="{{dropdowns['selection'][1].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext3'].value}}"
                        value="{{dropdowns['selection'][2].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext4'].value}}"
                        value="{{dropdowns['selection'][3].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext5'].value}}"
                        value="{{dropdowns['selection'][4].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext6'].value}}"
                        value="{{dropdowns['selection'][5].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext7'].value}}"
                        value="{{dropdowns['selection'][6].value}}"></ix-select-item>
                    <ix-select-item label="{{basisInfoForm.controls['servicetext8'].value}}"
                        value="{{dropdowns['selection'][7].value}}"></ix-select-item>
                </ix-select>
            </ix-col>
          </ix-row>
          <ix-row *ngIf="basisInfoForm.controls['simplecalc'].value == 'Yes' && basisInfoForm.controls['selection'].value != 1">
            <ix-col size="2" size-sm="12">
              <label class="tooltip-hosts" aria-describedby="tooltip-hosts">Hosts</label>
              <ix-tooltip id="tooltip-hosts" for=".tooltip-hosts">{{basisInfoForm.controls['hostcounttooltiptext'].value}}</ix-tooltip>   
            </ix-col>
            <ix-col>
                <input type="number" formControlName="hostcount" min="0" step="1">
                <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
            </ix-col>
          </ix-row>
      </ix-layout-grid>

    <div class="button-container">
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>
