<form [formGroup]="servicesForm" class="form was-validated">
    <ix-typography format="h3">Services</ix-typography>
    <div class="container">
        <div class="column">
            <ix-typography format="h4">Available</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="7" size-sm="12">
                    <label class="tooltip-ContinuousMonitoring" aria-describedby="tooltip-ContinuousMonitoring">Continuous Monitoring</label>
                    <ix-tooltip id="tooltip-ContinuousMonitoring" for=".tooltip-ContinuousMonitoring">{{servicesForm.controls['ContinuousMonitoringtooltiptext'].value}}</ix-tooltip> 
                  </ix-col>
                  <ix-col>
                    <ix-col>
                      <input type="text" value="included" readonly/>
                  </ix-col>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-IncidentManagement" aria-describedby="tooltip-IncidentManagement">Incident Management</label>
                      <ix-tooltip id="tooltip-IncidentManagement" for=".tooltip-IncidentManagement">{{servicesForm.controls['IncidentManagementtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="IncidentManagement">
                            <ix-select-item *ngFor="let option of dropdowns['IncidentManagement']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-NetworkManagement" aria-describedby="tooltip-NetworkManagement">Network Management</label>
                      <ix-tooltip id="tooltip-NetworkManagement" for=".tooltip-NetworkManagement">{{servicesForm.controls['NetworkManagementtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="NetworkManagement">
                            <ix-select-item *ngFor="let option of dropdowns['NetworkManagement']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-DomainManagement" aria-describedby="tooltip-DomainManagement">Domain Management</label>
                      <ix-tooltip id="tooltip-DomainManagement" for=".tooltip-DomainManagement">{{servicesForm.controls['DomainManagementtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="DomainManagement">
                            <ix-select-item *ngFor="let option of dropdowns['DomainManagement']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>

        <div class="column">
            <ix-typography format="h4">Maintained</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="7" size-sm="12">
                    <label class="tooltip-PreventivePeriodicMaintenance" aria-describedby="tooltip-PreventivePeriodicMaintenance">Preventive & Periodic Maintenance</label>
                    <ix-tooltip id="tooltip-PreventivePeriodicMaintenance" for=".tooltip-PreventivePeriodicMaintenance">{{servicesForm.controls['Preventive&PeriodicMaintenancetooltiptext'].value}}</ix-tooltip> 
                  </ix-col>
                  <ix-col>
                    <ix-select class="ix-select" name="ix-select-control" formControlName="Preventive&PeriodicMaintenance">
                        <ix-select-item *ngFor="let option of dropdowns['Preventive&PeriodicMaintenance']" label="{{option.value}}" value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                  </ix-col>
                </ix-row>
                <ix-row *ngIf="servicesForm.controls['Preventive&PeriodicMaintenance'].value == 'Yes'">
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-PreventiveSchedulefrequencyPP" aria-describedby="tooltip-PreventiveSchedulefrequencyPP">Preventive & Periodic Maintenance - Schedule frequency</label>
                      <ix-tooltip id="tooltip-PreventiveSchedulefrequencyPP" for=".tooltip-PreventiveSchedulefrequencyPP">{{servicesForm.controls['SchedulefrequencyPPtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="SchedulefrequencyPP">
                            <ix-select-item *ngFor="let option of dropdowns['SchedulefrequencyPP']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-Updatespacthes" aria-describedby="tooltip-Updatespacthes">Updates & patches</label>
                      <ix-tooltip id="tooltip-Updatespacthes" for=".tooltip-Updatespacthes">{{servicesForm.controls['Updates&pacthestooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="Updates&pacthes">
                            <ix-select-item *ngFor="let option of dropdowns['Updates&pacthes']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row  *ngIf="servicesForm.controls['Updates&pacthes'].value == 'Yes'">
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-SchedulefrequencyUP" aria-describedby="tooltip-SchedulefrequencyUP">Updates & patches - Schedule frequency</label>
                      <ix-tooltip id="tooltip-SchedulefrequencyUP" for=".tooltip-SchedulefrequencyUP">{{servicesForm.controls['SchedulefrequencyUPtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="SchedulefrequencyUP">
                            <ix-select-item *ngFor="let option of dropdowns['SchedulefrequencyUP']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-Back-upRestore" aria-describedby="tooltip-Back-upRestore">Back-up & Restore</label>
                      <ix-tooltip id="tooltip-Back-upRestore" for=".tooltip-Back-upRestore">{{servicesForm.controls['Back-up&Restoretooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="Back-up&Restore">
                            <ix-select-item *ngFor="let option of dropdowns['Back-up&Restore']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>
    </div>

    <div class="container">
        <div class="column">
            <ix-typography format="h4">Secured</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="7" size-sm="12">
                    <label class="tooltip-VulnerabilityManagement" aria-describedby="tooltip-VulnerabilityManagement">Vulnerability Management</label>
                    <ix-tooltip id="tooltip-VulnerabilityManagement" for=".tooltip-VulnerabilityManagement">{{servicesForm.controls['VulnerabilityManagementtooltiptext'].value}}</ix-tooltip> 
                  </ix-col>
                  <ix-col>
                    <ix-select class="ix-select" name="ix-select-control" formControlName="VulnerabilityManagement">
                        <ix-select-item *ngFor="let option of dropdowns['VulnerabilityManagement']" label="{{option.value}}" value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-AnomalyDetection" aria-describedby="tooltip-AnomalyDetection">Anomaly Detection</label>
                      <ix-tooltip id="tooltip-AnomalyDetection" for=".tooltip-AnomalyDetection">{{servicesForm.controls['AnomalyDetectiontooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="AnomalyDetection">
                            <ix-select-item *ngFor="let option of dropdowns['AnomalyDetection']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-SOCaaS" aria-describedby="tooltip-SOCaaS">SOCaaS (24/7)</label>
                      <ix-tooltip id="tooltip-SOCaaS" for=".tooltip-SOCaaS">{{servicesForm.controls['SOCaaStooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="SOCaaS">
                            <ix-select-item *ngFor="let option of dropdowns['SOCaaS']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>
        <div class="column">
            <ix-typography format="h4">Supported</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="7" size-sm="12">
                    <label class="tooltip-RemoteSupport" aria-describedby="tooltip-RemoteSupport">Remote Support</label>
                    <ix-tooltip id="tooltip-RemoteSupport" for=".tooltip-RemoteSupport">{{servicesForm.controls['RemoteSupporttooltiptext'].value}}</ix-tooltip> 
                  </ix-col>
                  <ix-col>
                    <ix-select class="ix-select" name="ix-select-control" formControlName="RemoteSupport">
                        <ix-select-item *ngFor="let option of dropdowns['RemoteSupport']" label="{{option.value}}" value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-ServiceDesk" aria-describedby="tooltip-ServiceDesk">Service Desk</label>
                      <ix-tooltip id="tooltip-ServiceDesk" for=".tooltip-ServiceDesk">{{servicesForm.controls['ServiceDesktooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="ServiceDesk">
                            <ix-select-item *ngFor="let option of dropdowns['ServiceDesk']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-ServiceRequest" aria-describedby="tooltip-ServiceRequest">Service Request</label>
                      <ix-tooltip id="tooltip-ServiceRequest" for=".tooltip-ServiceRequest">{{servicesForm.controls['ServiceRequesttooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="ServiceRequest">
                            <ix-select-item *ngFor="let option of dropdowns['ServiceRequest']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row *ngIf="servicesForm.controls['ServiceRequest'].value == 'Yes'">
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-ServiceRequesthours" aria-describedby="tooltip-ServiceRequesthours">Service Request (hours)</label>
                      <ix-tooltip id="tooltip-ServiceRequesthours" for=".tooltip-ServiceRequesthours">{{servicesForm.controls['ServiceRequesthourstooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-input-group>
                            <input type="number" formControlName="ServiceRequesthours" min="0" step="1">
                            <span slot="input-end">hours covered</span>
                            <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                        </ix-input-group> 
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label>Technical Product & Hardware Support</label>
                    </ix-col>
                    <ix-col>
                        <input type="text" value="included" readonly/>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>
    </div>
    <div class="container">
        <div class="column">
            <ix-typography format="h4">Onboarding services</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="7" size-sm="12">
                    <label class="tooltip-cRSP" aria-describedby="tooltip-cRSP">cRSP setup needed?</label>
                    <ix-tooltip id="tooltip-cRSP" for=".tooltip-cRSP">{{servicesForm.controls['cRSPtooltiptext'].value}}</ix-tooltip> 
                  </ix-col>
                  <ix-col>
                    <ix-select class="ix-select" name="ix-select-control" formControlName="cRSP">
                        <ix-select-item *ngFor="let option of dropdowns['cRSP']" label="{{option.value}}" value="{{option.value}}">
                        </ix-select-item>
                    </ix-select>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-onboardingcustomer" aria-describedby="tooltip-onboardingcustomer">onboarding customer</label>
                      <ix-tooltip id="tooltip-onboardingcustomer" for=".tooltip-onboardingcustomer">{{servicesForm.controls['onboardingcustomertooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="onboardingcustomer">
                            <ix-select-item *ngFor="let option of dropdowns['onboardingcustomer']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row *ngIf="servicesForm.controls['onboardinganomalyvisible'].value == 'Yes'">
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-onboardinganomaly" aria-describedby="tooltip-onboardinganomaly">onboarding anomaly</label>
                      <ix-tooltip id="tooltip-onboardinganomaly" for=".tooltip-onboardinganomaly">{{servicesForm.controls['onboardinganomalytooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="onboardinganomaly">
                            <ix-select-item *ngFor="let option of dropdowns['onboardinganomaly']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="7" size-sm="12">
                      <label class="tooltip-onboardingSOCaaS" aria-describedby="tooltip-onboardingSOCaaS">onboarding SOCaaS</label>
                      <ix-tooltip id="tooltip-onboardingSOCaaS" for=".tooltip-onboardingSOCaaS">{{servicesForm.controls['onboardingSOCaaStooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <ix-select class="ix-select" name="ix-select-control" formControlName="onboardingSOCaaS">
                            <ix-select-item *ngFor="let option of dropdowns['onboardingSOCaaS']" label="{{option.value}}" value="{{option.value}}">
                            </ix-select-item>
                        </ix-select>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>
        <div class="column">
        </div>
    </div>

    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>