<div *ngIf="currentProject">
    <ix-content-header slot="header" hasBackButton="true" [headerTitle]="currentProject.name" (click)="backButtonClick()">
    </ix-content-header>
    <ix-workflow-steps [selectedIndex]="stateMaschine.currentState" class="workflow" [clickable]="getFormGroup(stateMaschine.currentState).valid">
        <ix-workflow-step status="open" (click)="selectWorkflowStep(State.Step1)">Basics</ix-workflow-step>
        <ix-workflow-step [hidden]="isSimpleCalculation" status="open" (click)="selectWorkflowStep(State.Step2)">Assets Inventory</ix-workflow-step>
        <ix-workflow-step [hidden]="isSimpleCalculation" status="open" (click)="selectWorkflowStep(State.Step3)">Services</ix-workflow-step>
        <ix-workflow-step status="open" (click)="selectWorkflowStep(State.Step4)">Pricing</ix-workflow-step>
        <ix-workflow-step status="open" (click)="selectWorkflowStep(State.Step5)">Results</ix-workflow-step>
    </ix-workflow-steps>
    <div *ngIf="stateMaschine.currentState == State.Step1" class="container">
       <app-basic-info [basisInfoForm]="basisInfoForm" (nextStep)="NextStep()" (simpleCalculation)="ChangeSimpleCalculation($event)"></app-basic-info> 
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step2" class="container">
        <app-equipment-inventory [equipmentInventoryForm]="equipmentInventoryForm" (nextStep)="NextStep()" (stepBack)="StepBack()"></app-equipment-inventory>
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step3" class="container">
        <app-services [servicesForm]="servicesForm" (nextStep)="NextStep()" (stepBack)="StepBack()"></app-services>
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step4" class="container">
        <app-pricing [pricingForm]="pricingForm"  (nextStep)="NextStep()" (stepBack)="StepBack()"></app-pricing>
    </div>
    <div *ngIf="stateMaschine.currentState == State.Step5" class="container">
        <app-contract [contractForm]="contractForm" (stepBack)="StepBack()"></app-contract>    </div>

</div>
