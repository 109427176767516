<form [formGroup]="equipmentInventoryForm" class="form was-validated">
    <ix-typography format="h3">Assets Inventory</ix-typography>
    <div class="container">
        <div class="column">
            <ix-typography format="h4">Automation Production Systems</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="6" size-sm="12">
                    <label class="tooltip-engineeringstation" aria-describedby="tooltip-engineeringstation">Engineering station</label>
                    <ix-tooltip id="tooltip-engineeringstation" for=".tooltip-engineeringstation">{{equipmentInventoryForm.controls['Engineeringstationtooltiptext'].value}}</ix-tooltip>  
                  </ix-col>
                  <ix-col>
                    <input type="number" formControlName="Engineeringstation" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-OSServersincluding_BatchandRoutecontrol" aria-describedby="tooltip-OSServersincluding_BatchandRoutecontrol">OS Servers including (Batch and Route control)</label>
                      <ix-tooltip id="tooltip-OSServersincluding_BatchandRoutecontrol" for=".tooltip-OSServersincluding_BatchandRoutecontrol">{{equipmentInventoryForm.controls['OSServersincluding_BatchandRoutecontroltooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="OSServersincluding_BatchandRoutecontrol" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-OSClients" aria-describedby="tooltip-OSClients">OS Clients</label>
                      <ix-tooltip id="tooltip-OSClients" for=".tooltip-OSClients">{{equipmentInventoryForm.controls['OSClientstooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="OSClients" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-Dataclients" aria-describedby="tooltip-Dataclients">Data Clients</label>
                      <ix-tooltip id="tooltip-Dataclients" for=".tooltip-Dataclients">{{equipmentInventoryForm.controls['Dataclientstooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="Dataclients" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-OtherProductionsystems" aria-describedby="tooltip-OtherProductionsystems">Other Production systems</label>
                      <ix-tooltip id="tooltip-OtherProductionsystems" for=".tooltip-OtherProductionsystems">{{equipmentInventoryForm.controls['OtherProductionsystemstooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="OtherProductionsystems" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>   
            
            <br>

            <ix-typography format="h4">HyperVisor Hardware</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="6" size-sm="12">
                    <label class="tooltip-Hosts" aria-describedby="tooltip-Hosts">Hosts</label>
                    <ix-tooltip id="tooltip-Hosts" for=".tooltip-Hosts">{{equipmentInventoryForm.controls['Hoststooltiptext'].value}}</ix-tooltip>  
                  </ix-col>
                  <ix-col>
                    <input type="number" formControlName="Hosts" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-SAN_vSANsolution" aria-describedby="tooltip-SAN_vSANsolution">SAN/vSAN solution</label>
                      <ix-tooltip id="tooltip-SAN_vSANsolution" for=".tooltip-SAN_vSANsolution">{{equipmentInventoryForm.controls['SAN_vSANsolutiontooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="SAN_vSANsolution" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-iDMZhost" aria-describedby="tooltip-iDMZhost">iDMZ host</label>
                      <ix-tooltip id="tooltip-iDMZhost" for=".tooltip-iDMZhost">{{equipmentInventoryForm.controls['iDMZhosttooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="iDMZhost" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-coreswitches" aria-describedby="tooltip-coreswitches">core switches</label>
                      <ix-tooltip id="tooltip-coreswitches" for=".tooltip-coreswitches">{{equipmentInventoryForm.controls['coreswitchestooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="coreswitches" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-firewalls" aria-describedby="tooltip-firewalls">firewalls</label>
                      <ix-tooltip id="tooltip-firewalls" for=".tooltip-firewalls">{{equipmentInventoryForm.controls['firewallstooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="firewalls" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-SIDSIBR" aria-describedby="tooltip-SIDSIBR">SIDSI B&R</label>
                      <ix-tooltip id="tooltip-SIDSIBR" for=".tooltip-SIDSIBR">{{equipmentInventoryForm.controls['SIDSIB&Rtooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="SIDSIB&R" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-PH" aria-describedby="tooltip-PH">PH</label>
                      <ix-tooltip id="tooltip-PH" for=".tooltip-PH">{{equipmentInventoryForm.controls['PHtooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="PH" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-UPS" aria-describedby="tooltip-UPS">UPS</label>
                      <ix-tooltip id="tooltip-UPS" for=".tooltip-UPS">{{equipmentInventoryForm.controls['UPStooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="UPS" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-NAS" aria-describedby="tooltip-NAS">NAS</label>
                      <ix-tooltip id="tooltip-NAS" for=".tooltip-NAS">{{equipmentInventoryForm.controls['NAStooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="NAS" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-USBconnectorHUB" aria-describedby="tooltip-USBconnectorHUB">USBconnectorHUB</label>
                      <ix-tooltip id="tooltip-USBconnectorHUB" for=".tooltip-USBconnectorHUB">{{equipmentInventoryForm.controls['USBconnectorHUBtooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="USBconnectorHUB" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-Thinclients" aria-describedby="tooltip-Thinclients">Thin clients</label>
                      <ix-tooltip id="tooltip-Thinclients" for=".tooltip-Thinclients">{{equipmentInventoryForm.controls['Thinclientstooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="Thinclients" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>

        <div class="column">
            <ix-typography format="h4">Supporting systems</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="6" size-sm="12">
                    <label class="tooltip-DataExchangeServer" aria-describedby="tooltip-DataExchangeServer">Data Exchange Server</label>
                    <ix-tooltip id="tooltip-DataExchangeServer" for=".tooltip-DataExchangeServer">{{equipmentInventoryForm.controls['DataExchangeServertooltiptext'].value}}</ix-tooltip>  
                  </ix-col>
                  <ix-col>
                    <input type="number" formControlName="DataExchangeServer" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-Jumphost" aria-describedby="tooltip-Jumphost">Jumphost</label>
                      <ix-tooltip id="tooltip-Jumphost" for=".tooltip-Jumphost">{{equipmentInventoryForm.controls['Jumphosttooltiptext'].value}}</ix-tooltip>  
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="Jumphost" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-Domain" aria-describedby="tooltip-Domain">Domain</label>
                      <ix-tooltip id="tooltip-Domain" for=".tooltip-Domain">{{equipmentInventoryForm.controls['Domaintooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="Domain" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-NetworkMonitorServer" aria-describedby="tooltip-NetworkMonitorServer">Network Monitor Server</label>
                      <ix-tooltip id="tooltip-NetworkMonitorServer" for=".tooltip-NetworkMonitorServer">{{equipmentInventoryForm.controls['NetworkMonitorServertooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="NetworkMonitorServer" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-Management" aria-describedby="tooltip-Management">Management</label>
                      <ix-tooltip id="tooltip-Management" for=".tooltip-Management">{{equipmentInventoryForm.controls['Managementtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="Management" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-DNS" aria-describedby="tooltip-DNS">DNS</label>
                      <ix-tooltip id="tooltip-DNS" for=".tooltip-DNS">{{equipmentInventoryForm.controls['DNStooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="DNS" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-EndpointProtection" aria-describedby="tooltip-EndpointProtection">Endpoint Protection</label>
                      <ix-tooltip id="tooltip-EndpointProtection" for=".tooltip-EndpointProtection">{{equipmentInventoryForm.controls['EndpointProtectiontooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="EndpointProtection" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-InformationServer" aria-describedby="tooltip-InformationServer">Information Server</label>
                      <ix-tooltip id="tooltip-InformationServer" for=".tooltip-InformationServer">{{equipmentInventoryForm.controls['InformationServertooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="InformationServer" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-UpdateServer_WSUS" aria-describedby="tooltip-UpdateServer_WSUS">Update Server (WSUS)</label>
                      <ix-tooltip id="tooltip-UpdateServer_WSUS" for=".tooltip-UpdateServer_WSUS">{{equipmentInventoryForm.controls['UpdateServer_WSUStooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="UpdateServer_WSUS" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-OtherWindows" aria-describedby="tooltip-OtherWindows">Other Windows</label>
                      <ix-tooltip id="tooltip-OtherWindows" for=".tooltip-OtherWindows">{{equipmentInventoryForm.controls['OtherWindowstooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="OtherWindows" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-OtherOS" aria-describedby="tooltip-OtherOS">Other OS</label>
                      <ix-tooltip id="tooltip-OtherOS" for=".tooltip-OtherOS">{{equipmentInventoryForm.controls['OtherOStooltiptext'].value}}</ix-tooltip> 
                      <label>Other OS</label>
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="OtherOS" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>

            <br>

            <ix-typography format="h4">Additional Hardware</ix-typography>
            <ix-layout-grid class="layout-grid-form" gap="12">
                <ix-row>
                  <ix-col size="6" size-sm="12">
                    <label class="tooltip-SwitchesPCN" aria-describedby="tooltip-SwitchesPCN">Switches PCN</label>
                    <ix-tooltip id="tooltip-SwitchesPCN" for=".tooltip-SwitchesPCN">{{equipmentInventoryForm.controls['SwitchesPCNtooltiptext'].value}}</ix-tooltip> 
                  </ix-col>
                  <ix-col>
                    <input type="number" formControlName="SwitchesPCN" min="0" step="1">
                    <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                  </ix-col>
                </ix-row>
                <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-SwitchesCSN" aria-describedby="tooltip-SwitchesCSN">Switches CSN</label>
                      <ix-tooltip id="tooltip-SwitchesCSN" for=".tooltip-SwitchesCSN">{{equipmentInventoryForm.controls['SwitchesCSNtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="SwitchesCSN" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row>
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-SwitchesFSN" aria-describedby="tooltip-SwitchesFSN">Switches FSN</label>
                      <ix-tooltip id="tooltip-SwitchesFSN" for=".tooltip-SwitchesFSN">{{equipmentInventoryForm.controls['SwitchesFSNtooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="SwitchesFSN" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
                  <ix-row  *ngIf="equipmentInventoryForm.controls['PLC-ASvisible'].value == '1'">
                    <ix-col size="6" size-sm="12">
                      <label class="tooltip-PLC-AS" aria-describedby="tooltip-PLC-AS">PLC-AS</label>
                      <ix-tooltip id="tooltip-PLC-AS" for=".tooltip-PLC-AS">{{equipmentInventoryForm.controls['PLC-AStooltiptext'].value}}</ix-tooltip> 
                    </ix-col>
                    <ix-col>
                        <input type="number" formControlName="PLC-AS" min="0" step="1">
                        <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>
                    </ix-col>
                  </ix-row>
            </ix-layout-grid>
        </div>
    </div>

    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>