import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UIElements } from 'src/const-data/ui-elements';
import { ValidationFeedback } from 'src/const-data/validation-feedback';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit{
@Output()
  nextStep: EventEmitter<void> = new EventEmitter();
  @Output()
  stepBack: EventEmitter<void> = new EventEmitter();

  @Input()
  pricingForm!: FormGroup;
  
  validationFeedback = ValidationFeedback;
  dropdowns = UIElements.dropDowns;
  
  constructor(private dataService: DataService){  
  }

  async ngOnInit(): Promise<void> {  
    await this.dataService.readData(this.pricingForm);
  } 

  NextStep() {
    this.nextStep.emit();
  }

  StepBack() {
    this.stepBack.emit();
  }
}
