<form class="form" [formGroup]="contractForm">
    <ix-typography format="h3">Results</ix-typography>
    
    <ix-layout-grid class="layout-grid-form" gap="12">
        <ix-row>
            <ix-col size="4" size-sm="12">
            </ix-col>
            <ix-col>
                <ix-typography class="price-type" format="h4">{{contractForm.controls['typeofpricing1'].value}}</ix-typography>
            </ix-col>
          </ix-row>
        <ix-row>
          <ix-col size="4" size-sm="12">
            <label class="tooltip-onboarding_total" aria-describedby="tooltip-onboarding_total">onboarding (total)</label>
            <ix-tooltip id="tooltip-onboarding_total" for=".tooltip-onboarding_total">{{contractForm.controls['onboarding_totaltooltiptext'].value}}</ix-tooltip> 
          </ix-col>
          <ix-col>
            <ix-input-group>
                <input type="number" formControlName="onboarding_total" readonly/>
                <span slot="input-end">€</span>
              </ix-input-group>
          </ix-col>
        </ix-row>
        <ix-row>
            <ix-col size="4" size-sm="12">
              <label class="tooltip-requestbudget_reimbursable" aria-describedby="tooltip-requestbudget_reimbursable">request budget (reimbursable)</label>
              <ix-tooltip id="tooltip-requestbudget_reimbursable" for=".tooltip-requestbudget_reimbursable">{{contractForm.controls['requestbudget_reimbursabletooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-input-group>
                    <input type="number" formControlName="requestbudget_reimbursable" readonly/>
                    <span slot="input-end">hours</span>
                  </ix-input-group>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-col size="4" size-sm="12">
              <label class="tooltip-RIOpSpackageoprice" aria-describedby="tooltip-RIOpSpackageoprice">{{contractForm.controls['RIOpspackacketext'].value}}</label>
              <ix-tooltip id="tooltip-RIOpSpackageoprice" for=".tooltip-RIOpSpackageoprice">{{contractForm.controls['RIOpSpackageopricetooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-input-group>
                    <input type="number" formControlName="RIOpSpackageoprice" readonly/>
                    <span slot="input-end">€</span>
                  </ix-input-group>
            </ix-col>
          </ix-row>
          <ix-row  *ngIf="contractForm.controls['RIOpSDiscountVisible'].value == 'true'">
            <ix-col size="4" size-sm="12">
              <label class="tooltip-RIOpSDiscount" aria-describedby="tooltip-RIOpSDiscount">RIOpS Discount</label>
              <ix-tooltip id="tooltip-RIOpSDiscount" for=".tooltip-RIOpSDiscount">{{contractForm.controls['RIOpSDiscounttooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-input-group>
                    <input type="number" formControlName="RIOpSDiscount" readonly/>
                    <span slot="input-end">€</span>
                  </ix-input-group>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-col size="4" size-sm="12">
              <label class="tooltip-totalprice" aria-describedby="tooltip-totalprice">TOTAL PRICE</label>
              <ix-tooltip id="tooltip-totalprice" for=".tooltip-totalprice">{{contractForm.controls['totalpricetooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-input-group>
                    <input type="number" formControlName="totalprice" readonly/>
                    <span slot="input-end">€</span>
                  </ix-input-group>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-typography class="disclaimer" bold="true">{{contractForm.controls['genernaldisclaimertext'].value}}</ix-typography>
          </ix-row>
          <ix-row>
            <ix-typography>{{contractForm.controls['Versiontext'].value}}</ix-typography>
          </ix-row>
          <ix-row>
            <ix-typography class="line-breaks" textColor="soft">{{contractForm.controls['descriptiontext'].value}}</ix-typography>
          </ix-row>
    </ix-layout-grid>
    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
    </div>
</form>
