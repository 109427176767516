import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { BehaviorSubject, filter, map } from 'rxjs';

export interface LocalUser {
  firstName: string;
  lastName: string;
  fullName: string;
  initials: string;
  picture: string | undefined;
  isAdmin: boolean;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userSubject = new BehaviorSubject(this.createDefaultUser());
  user$ = this.userSubject.asObservable();

  constructor(authService: AuthService) {
    authService.user$
      .pipe(
        filter(user => user !== null && user !== undefined),
        map((user) => this.fromAuth0User(user!))
      )
      .subscribe((user) => this.userSubject.next(user));
  }

  private fromAuth0User(auth0User: User): LocalUser {
    return {
      firstName: auth0User.given_name as string,
      lastName: auth0User.family_name as string,
      fullName: (auth0User.given_name ? auth0User.given_name + " " : "") + (auth0User.family_name ?? ""),
      initials: this.getInitials(auth0User),
      picture: auth0User.picture,
      email: auth0User.email as string,
      isAdmin:
        auth0User['https://siemens.com/roles'].includes('RIOpS Web Calculator Admin')
    };
  }

  private getInitials(auth0User: User): string {
    let firstLetter = auth0User?.family_name?.charAt(0) ?? "";
    let secondLetter = auth0User?.given_name?.charAt(0) ?? "";
    let initials: string = firstLetter + secondLetter;

    return initials;
  }

  createDefaultUser(): LocalUser {
    return {
      firstName: '',
      lastName: '',
      fullName: '',
      initials: '',
      picture: undefined,
      isAdmin: false,
      email: ''
    };
  }
}
