<ng-template #addProjectModal let-modal>
  <div>
    <form [formGroup]="projectForm" class="needs-validation m-2">
      <ix-modal-header> Create new project </ix-modal-header>
      <ix-modal-content>
        <label for="name">Name</label>
        <input id="name" formControlName="name" type="text" />
      </ix-modal-content>
      <ix-modal-footer>
        <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
          Cancel
        </ix-button>
        <ix-button class="close-modal" (click)="modal.close()" [disabled]="!projectForm.valid">
          Save
        </ix-button>
      </ix-modal-footer>
    </form>
  </div>
</ng-template>

<ng-template #editProjectModal let-modal>
  <div>
    <form [formGroup]="projectForm" class="needs-validation m-2">
      <ix-modal-header> Edit project </ix-modal-header>
      <ix-modal-content>
        <label for="name">Name</label>
        <input id="name" formControlName="name" type="text" />
      </ix-modal-content>
      <ix-modal-footer>
        <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
          Cancel
        </ix-button>
        <ix-button class="close-modal" (click)="modal.close()" [disabled]="!projectForm.valid">
          Update
        </ix-button>
      </ix-modal-footer>
    </form>
  </div>
</ng-template>

<ng-template #deleteProjectModal let-modal>
  <div>
    <ix-modal-header>Delete project</ix-modal-header>
    <ix-modal-content>Are you sure you want to delete "{{ modal.data }}"?</ix-modal-content>
    <ix-modal-footer>
      <ix-button outline class="dismiss-modal" (click)="modal.dismiss()">
        Cancel
      </ix-button>
      <ix-button class="close-modal" (click)="modal.close()">
        Delete
      </ix-button>
    </ix-modal-footer>
  </div>
</ng-template>