<form [formGroup]="pricingForm" class="form was-validated">
    <ix-typography format="h3">Pricing</ix-typography>
    <ix-layout-grid class="layout-grid-form" gap="12">
        <ix-row>
          <ix-col size="2" size-sm="12">
            <label class="tooltip-Contractduration" aria-describedby="tooltip-Contractduration">Contract duration</label>
            <ix-tooltip id="tooltip-Contractduration" for=".tooltip-Contractduration">{{pricingForm.controls['Contractdurationtooltiptext'].value}}</ix-tooltip> 
          </ix-col>
          <ix-col>
            <ix-select class="ix-select input-width" name="ix-select-control" formControlName="Contractduration" [readonly]="pricingForm.controls['simplecalc'].value === 'MLFB'">
                <ix-select-item *ngFor="let option of dropdowns['Contractduration']" label="{{option.value}}" value="{{option.value}}">
                </ix-select-item>
            </ix-select>
          </ix-col>
        </ix-row>
        <ix-row>
            <ix-col size="2" size-sm="12">
              <label class="tooltip-typeofpricing" aria-describedby="tooltip-typeofpricing">type of pricing</label>
              <ix-tooltip id="tooltip-typeofpricing" for=".tooltip-typeofpricing">{{pricingForm.controls['typeofpricingtooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-select class="ix-select input-width" name="ix-select-control" formControlName="typeofpricing">
                    <ix-select-item *ngFor="let option of dropdowns['typeofpricing']" label="{{option.label}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </ix-col>
          </ix-row>
          <ix-row *ngIf="pricingForm.controls['Contractduration'].value > 1">
            <ix-col size="2" size-sm="12">
              <label class="tooltip-indexation" aria-describedby="tooltip-indexation">indexation</label>
              <ix-tooltip id="tooltip-indexation" for=".tooltip-indexation">{{pricingForm.controls['indexationtooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-input-group>
                    <input type="number" formControlName="indexation" min="0" step="0.01"/>
                    <span slot="input-end">%</span>
                  </ix-input-group>
                <!--  <div class="invalid-feedback"> {{validationFeedback.negativeInteger}}</div>-->
            </ix-col>
          </ix-row>
          <ix-row *ngIf="pricingForm.controls['Contractduration'].value > 1">
            <ix-col size="2" size-sm="12">
              <label class="tooltip-activatediscount" aria-describedby="tooltip-activatediscount">activatediscount</label>
              <ix-tooltip id="tooltip-activatediscount" for=".tooltip-activatediscount">{{pricingForm.controls['activatediscounttooltiptext'].value}}</ix-tooltip> 
            </ix-col>
            <ix-col>
                <ix-select class="ix-select input-width" name="ix-select-control" formControlName="activatediscount">
                    <ix-select-item *ngFor="let option of dropdowns['activatediscount']" label="{{option.value}}" value="{{option.value}}">
                    </ix-select-item>
                </ix-select>
            </ix-col>
          </ix-row>
          <ix-row>
            <ix-typography *ngIf="pricingForm.controls['Contractduration'].value > 1" class="disclaimer" bold="true">
                {{pricingForm.controls['indexdisclaimer'].value}}
            </ix-typography>
          </ix-row>
    </ix-layout-grid>

    <div class="button-container">
        <ix-button class="m-1" outline variant="primary" class="back-button" (click)="StepBack()">Back</ix-button>
        <ix-button class="next-button" (click)="NextStep()">Next</ix-button>
    </div>
</form>
