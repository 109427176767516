import { Component, ElementRef, ViewChild } from '@angular/core';
import { FileTransferService } from '../services/file-transfer.service';

@Component({
  selector: 'app-masterfile-management',
  templateUrl: './masterfile-management.component.html',
  styleUrls: ['./masterfile-management.component.css']
})
export class MasterfileManagementComponent {

  @ViewChild('fileSelection', { static: true })
  fileSelection: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('projectFileSelection', { static: true })
  projectFileSelection: ElementRef<HTMLInputElement> | undefined;

  masterFileToUpload: File | undefined;
  projectFileToUpload: File | undefined;

  constructor(private fileTransferService: FileTransferService){ }

  async storeFile(){

    this.masterFileToUpload = this.fileSelection?.nativeElement.files?.item(0) || undefined;

  }

  async storeProjectFile(){

    this.projectFileToUpload = this.projectFileSelection?.nativeElement.files?.item(0) || undefined;

  }

  

  downloadMasterFile(){
    this.fileTransferService.downloadFile('Masterfile');
  }

  uploadMasterfile(){

    if (this.masterFileToUpload !== undefined){

      this.fileTransferService.uploadFile('Masterfile', this.masterFileToUpload);
    }
  }

  uploadProjectFile(){

    if (this.projectFileToUpload !== undefined){

      this.fileTransferService.uploadFile('Project', this.projectFileToUpload);
    }
  }
}
